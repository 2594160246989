<template>
  <div class="th-det-patient-header--wrp" v-if="initialData">
    <modal-element
      @emitModuleCondition="closeModal"
      :type-modal="modalData.type"
    />
    <div class="th-det-patient-header--wrp---child">
      <div class="th-det-patient-header--wrp---child--page"
           v-if="initialData.PatientInfo">
        <b-link :to="{name: 'doctorPatientpage'}">
          <b-icon icon="arrow-left"></b-icon>
        </b-link>
        <h2>
          {{ $t('Patient', currentSelectedLanguage) }}
        </h2>
        <h2>
          {{ initialData.PatientInfo.ListNumber }}
        </h2>
        <h2>
          {{ initialData.PatientInfo.ClinicAccountId }}
        </h2>
        <h2>
          {{ initialData.PatientInfo.Id }}
        </h2>
      </div>
      <div class="th-det-patient-header--wrp---child--button">
        <narrow-button
          buttonTextNarrow="Edit Patient ID"
          @narrowButtonEventEmit="openEditmodal"
        />
        <narrow-button
          buttonTextNarrow="Delete Patient"
          @narrowButtonEventEmit="openDeletemodal"
        />
        <narrow-button
          buttonTextNarrow="Edit Birth Date"
          @narrowButtonEventEmit="openSetDateOfBirthModal"
        />
      </div>
      <div class="th-det-patient-header--wrp---child--drinfo">
        <p class="text-right">
          {{ userProfile.Name }}
        </p>
        <p class="text-right">
          {{  userProfile.ClinicName }}
        </p>
      </div>
    </div>

    <div class="th-det-patient-header--wrp---child">
      <div v-if="simpleElement"  class="th-det-patient-header--wrp---child--stats">
        <number-with-text
          :numberProps="initialData.PatientInfo.FamilyHistoryOfBreastCancer?
                        'Yes':'No'"
          titleProps="Family History"
          :numberArrayProps="['number-text-prop']"
        />
        <number-with-text
          :numberProps="initialData.PatientInfo.Visits"
          titleProps="Visits"
          :numberArrayProps="['number-text-prop']"
        />
        <number-with-text
          :numberProps="initialData.PatientInfo.Scans"
          titleProps="Scans"
          :numberArrayProps="['number-text-prop']"
        />
        <number-with-text
          :numberProps="initialData.PatientInfo.FlaggedData"
          titleProps="FLAGGED DATA"
          :numberArrayProps="['number-text-prop']"
        />
      </div>
      <input-calendar
        v-if="simpleElement"
        :localFilter="simpleElement"
        :calendarInput="false"
        class="th-det-patient-header--wrp---child--calendar"
        :calendarInputPlaceholder="$t('Search date', currentSelectedLanguage)"
        @keyUpInputEventCalendar="setCalendarVisit"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NarrowButton from '../../../../atoms/narrowButton.vue';
import InputCalendar from '../../../../atoms/inputCalendar.vue';
import NumberWithText from '../../../../atoms/numberWithText.vue';
import modalElement from '../../../universalModal.vue';
import { SET_MODAL_ACTION } from '../../../../../store';

export default {
  name: 'patientDetailsHeader',
  components: {
    NumberWithText,
    InputCalendar,
    NarrowButton,
    modalElement,
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        modalData: 'getModal',
        initialData:'getPatientInitialData',
        userProfile: 'getProfile',
      },
    ),
  },
  props: {
    simpleElement: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async closeModal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: false,
        type: null,
      });
    },
    async openDeletemodal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'deletePatient',
      });
    },
    async openEditmodal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'patientId',
      });
    },
    async openSetDateOfBirthModal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'setDateOfBirth',
      });
    },
    setCalendarVisit(value) {
      this.$emit('inheritanceForCalendarVisit', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .th-wrpNmb:not(:first-child) {
    @include space($type:margin, $direction:left, $amount:28px);
  }
  .th-det-patient-header--wrp {
    display: flex;
    flex-direction: column;
    @include space($type:margin, $direction:bottom, $amount:24px);

    &---child {
    display: flex;
    flex-direction: row;

      &:first-child {
        @include space($type:margin, $direction:bottom, $amount:19px);
      }

      &--page {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > a {
          font-size: 21px;
          color: $charade;
          outline: 0;
        }

        & > h2 {
          @include font-source($openSans, 18px, $neo-future, 600, normal, normal);
          @include space($type:margin, $direction:bottom, $amount:0);

          &:nth-child(2) {
            @include space($type:margin, $direction:left, $amount:21px);
          }

          &:not(:nth-child(2)) {
            @include space($type:margin, $direction:left, $amount:6px);
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
          }

          &:last-child {
            color: rgba(21, 46, 65, 0.6);
          }
        }
      }

      &--button {
        display: flex;
        align-items: center;
        @include space($type:margin, $direction:left, $amount:15px);

        & > button {
          //&:last-child {
            @include space($type:margin, $direction:left, $amount:11px);
          //}
          text-transform: uppercase;
        }
      }

      &--drinfo {
        @include space($type: margin, $direction: left, $amount: auto);
        & > p {
          margin-bottom: 0;
          text-transform: capitalize;

          &:first-child {
            @include font-source($openSans, 12px, $secondary, 600, normal, normal);
          }

          &:last-child {
            @include font-source($openSans, 11px, rgba(30, 45, 62, 0.6), 600, normal, normal);
          }
        }
      }

      &--calendar {
        width: 280px;
        @include space($type:margin, $direction:left, $amount:auto);
      }

      &--stats {
        display: flex;
        flex-direction: row;
      }
    }
  }
</style>
