<template>
  <div class="th-det-patient֊wrp">
    <patient-details-header
      @inheritanceForCalendarVisit="filterCalendar"
    />
    <hr class="th-det-patient֊wrp--hr"/>
    <patient-table
      :element-cond-prop="elementCondition"
      :status-condition-local="detailFilteredData||''"
      @onSelectedRowEmitExpand="selectVisit"
      @filterEmit="selectFilter"
      :patientList="false"
      :filterProps="table.filter"
      :itemsPropsArray="initialData?initialData.PatientVisitsInfo:[]"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
import PatientDetailsHeader
  from '../../../../../components/moleculs/dashboard/patient/pageHead/patientDetailsHeader.vue';
import PatientTable
  from '../../../../../components/moleculs/dashboard/patient/pageBody/patientTable.vue';
import {
  GET_PATIENT_VISIT_INIT_DATA,
  GET_VISIT_DETAILS,
  REMOVE_VISIT_DETAILS,
  VISIT_DETAIL_FILTER_ACTION,
} from '../../../../../store/modules/patients.module';
import {ELEMENT_COND_DURING_LOAD} from '../../../../../store';
import localVariable from '../../../../../core/helpers/variables/localVariables';

export default {
  name: 'patientPageDetail',
  components: {PatientTable, PatientDetailsHeader},
  data() {
    return {
      table: {
        filter: null,
      },
      currentFilter: null,
      a: null,
      dateOfBirth: null,
    };
  },
  watch: {
    initialData: {
      handler: function (val, oldVal) {
        this.a = val.PatientInfo.ClinicAccountId;
        this.dateOfBirth = new Date(val.PatientInfo.DateOfBirth);
      },
      deep: true
    },
    '$route': {
      handler: function (route) {
        this.loadPatientVisitData();
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      // patient: 'getSelectedPatient',
      patientVisitDetails: 'getPatientVisitDetail',
      detailFilteredData: 'getFilterData',
      initialData: 'getPatientInitialData',
      elementCondition: 'elementCond',
    }),
  },
  methods: {
    autoSelectRow() {
      const x = document.getElementsByTagName("tr");
      if (x.length > 0
        && this.patientVisitDetails !== null) {
        x[this.patientVisitDetails.index + 1].click();
      }
    },
    selectFilter(val) {
      if (val == null) {
        this.table.filter = null;
      } else {
        this.table.filter = `${val}`;
      }
      this.currentFilter = val;
      this.$store.dispatch(VISIT_DETAIL_FILTER_ACTION, val);
    },
    destroyData() {
      this.table.filter = null;
      this.$store.dispatch(VISIT_DETAIL_FILTER_ACTION, null);
    },
    filterCalendar(value) {
      this.table.filter = moment(value.FirstDate).format("YYYY-MM-DD") !== 'Invalid date'
        ? moment(value.FirstDate).format("YYYY-MM-DD") : null;
    },
    async loadPatientVisitData() {
      await this.$store.dispatch(ELEMENT_COND_DURING_LOAD, localVariable.LOADER.LOADER_SET_VALUE);
      // await this.$store.dispatch(GET_PATIENT_DETAILS, { patientId: this.$route.params.patientId })
      //   .then((res) => {
      await this.$store.dispatch(GET_PATIENT_VISIT_INIT_DATA, {patientId: this.$route.params.patientId})
        .then(() => {
          this.$store.dispatch(ELEMENT_COND_DURING_LOAD, localVariable.LOADER.LOADER_REMOVE_VALUE);
          this.autoSelectRow();
        })
        .catch(() => {
          this.$store.dispatch(ELEMENT_COND_DURING_LOAD, localVariable.LOADER.LOADER_REMOVE_VALUE);
        });
      // })
      // .catch((e) => {
      //   this.$router.push({name: 'doctorPatientpage'})
      // });
    },
    selectVisit(value, index) {
      this.toggleDetails(value, index);
    },
    toggleDetails(item, index) {
      if (item._showDetails) {
        this.$store.dispatch(REMOVE_VISIT_DETAILS).then(() => {
          this.$set(item, '_showDetails', false)
        })
      } else {
        this.$store.dispatch(VISIT_DETAIL_FILTER_ACTION, item.RecordedMaxLabel);
        this.initialData.PatientVisitsInfo.forEach(item => {
          this.$store.dispatch(REMOVE_VISIT_DETAILS);
          this.$set(item, '_showDetails', false)
        })

        this.$nextTick(() => {
          let VisitId = {
            patientVisitId: item.VisitId,
            index: index,
          }
          this.$store.dispatch(GET_VISIT_DETAILS, VisitId).then(() => {
            this.$set(item, '_showDetails', true);
          })
        })
      }
    },
  },
  beforeDestroy() {
    this.destroyData();
  },
};
</script>

<style lang="scss" scoped>
.th-det-patient֊wrp {
  @include space($type: padding, $direction: top, $amount: 36px);
  @include space($type: padding, $direction: right, $amount: 32px);
  @include space($type: padding, $direction: bottom, $amount: 34px);
  @include space($type: padding, $direction: left, $amount: 30px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &--hr {
    @include border(1px, solid, rgba(30, 45, 62, 0.1), all);
    @include space($type: margin, $direction: top, $amount: 0);
    @include space($type: margin, $direction: bottom, $amount: 23px);
    width: 100%;
  }
}
</style>
